import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { EmailTemplateDataTable } from '@/helpers/types';
import {
  fetchEmailTemplate,
  searchEmailTemplates,
} from '@/redux/thunk/emailTemplateThunk';

interface EmailTemplate {
  emailTemplate: EmailTemplateDataTable[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: EmailTemplate = {
  emailTemplate: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const emailTemplateSlice = createSlice({
  name: 'emailTemplate',
  initialState,
  reducers: {
    clearTimesheet(state) {
      state.emailTemplate = initialState.emailTemplate;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailTemplate.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEmailTemplate.fulfilled,
        (state, action: PayloadAction<{ data: EmailTemplateDataTable[] }>) => {
          state.emailTemplate = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchEmailTemplate.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(searchEmailTemplates.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchEmailTemplates.fulfilled,
        (state, action: PayloadAction<{ data: EmailTemplateDataTable[] }>) => {
          state.emailTemplate = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(searchEmailTemplates.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearTimesheet } = emailTemplateSlice.actions;
export default emailTemplateSlice.reducer;
