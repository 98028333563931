import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_ACCOUNT_BILLING, API_METHOD_GET } from '@/constants/apiConstants';
import { urlEncode } from '@/helpers/common';

export const SearchAccountBillingsData = async (
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
) => {
  const method: Method = API_METHOD_GET;
  const url =
    API_ACCOUNT_BILLING +
    `?search=${urlEncode(searchTerm)}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  try {
    return await apiCall({
      method,
      url,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
