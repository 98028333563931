import { AddSupplier } from '@/api/supplier/addSupplier';
import { ChangeStatus } from '@/api/supplier/changeStatus';
import { DeleteSingleSupplier } from '@/api/supplier/deleteSingleSupplier';
import { ExportSuppliers } from '@/api/supplier/exportSuppliers';
import { GetAllSuppliers } from '@/api/supplier/getAllSuppliers';
import { GetSingleSupplier } from '@/api/supplier/getSingleSupplier';
import { ImportSuppliers } from '@/api/supplier/importSuppliers';
import { SearchSupplier } from '@/api/supplier/searchSupplier';
import { UpdateSupplier } from '@/api/supplier/updateSupplier';
import {
  AllSuppliersType,
  Pagination,
  SingleSupplierType,
  SupplierFormData,
} from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';

// Types
// Type of responses
type FetchAllSuppliersResponse = {
  data: AllSuppliersType[];
  pagination: Pagination;
};

type AddSupplierResponse = {
  data: SingleSupplierType;
  status: string;
};

type UpdateSupplierResponse = {
  data: SingleSupplierType;
  status: string;
};

type DeleteSupplierResponse = {
  status: string;
};

type ExportSuppliersResponse = {
  status: string;
};

type ImportSupplierResponse = {
  status: string;
};

type SupplierStatusUpdateResponse = {
  status: string
}

// Types of arguments
type SearchSupplierArgs = {
  searchTerm: string;
  filteredType: number | undefined;
  invoiceCountPerPage: number;
  pageNumber: number;
  filterDateRange: {
    start: string;
    end: string;
  };
};

type FetchSingleSupplierArgs = {
  supplierUuid: string;
};

export interface UpdatedSupplierFormData extends SupplierFormData {
  is_active: number;
  supplierUuid: string;
}

type DeleteSupplierArgs = {
  supplierUuid: string;
};

export interface ImportSupplierArgs {
  attachment: string;
}

export interface SupplierStatusUpdateFormData {
  supplierUuid: string
  is_active: number
}

type FetchSupplierError = string | SerializedError;

// Fetch all suppliers
export const fetchAllSuppliers = createAsyncThunk<
  FetchAllSuppliersResponse,
  void,
  { rejectValue: FetchSupplierError }
>('supplier/fetchAllSuppliers', async (_, { rejectWithValue }) => {
  try {
    const response = await GetAllSuppliers();
    const data: FetchAllSuppliersResponse = response.data;
    return { data: data.data, pagination: data.pagination };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
  }
  return rejectWithValue('An unknown error occurred');
});

// Fetch single supplier
export const fetchSingleSupplier = createAsyncThunk<
  SingleSupplierType,
  FetchSingleSupplierArgs,
  { rejectValue: FetchSupplierError }
>(
  'supplier/fetchSingleSupplier',
  async ({ supplierUuid }, { rejectWithValue }) => {
    try {
      const response = await GetSingleSupplier(supplierUuid);
      return response.data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      // if (error.response) {
      //   const { message, messages } = error.response.data;
      //   return rejectWithValue({ message, messages });
      // }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Fetch search suppliers
export const searchSupplier = createAsyncThunk<
  FetchAllSuppliersResponse,
  SearchSupplierArgs,
  { rejectValue: FetchSupplierError }
>(
  'supplier/fetchSearchSupplier',
  async (
    {
      searchTerm,
      filteredType,
      invoiceCountPerPage,
      pageNumber,
      filterDateRange,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchSupplier(
        searchTerm,
        filteredType,
        invoiceCountPerPage,
        pageNumber,
        filterDateRange,
      );
      const data: FetchAllSuppliersResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Add supplier
export const addSupplier = createAsyncThunk<
  AddSupplierResponse,
  SupplierFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplier/addSupplier',
  async (newSupplierData: SupplierFormData, { rejectWithValue }) => {
    try {
      const response = await AddSupplier(newSupplierData);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

// Update supplier
export const updateSupplier = createAsyncThunk<
  UpdateSupplierResponse,
  UpdatedSupplierFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplier/updateSupplier',
  async (updatedSupplierData: UpdatedSupplierFormData, { rejectWithValue }) => {
    try {
      const response = await UpdateSupplier(updatedSupplierData);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Update supplier status
export const updateSupplierStatus = createAsyncThunk<
  SupplierStatusUpdateResponse,
  SupplierStatusUpdateFormData,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplier/updateSupplierStatus',
  async (updatedStatusData: SupplierStatusUpdateFormData, { rejectWithValue }) => {
    try {
      console.log(updatedStatusData);

      const response = await ChangeStatus(updatedStatusData);
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Delete single supplier
export const deleteSingleSupplier = createAsyncThunk<
  DeleteSupplierResponse,
  DeleteSupplierArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplier/deleteSingleSupplier',
  async ({ supplierUuid }, { dispatch, rejectWithValue }) => {
    try {
      const response = await DeleteSingleSupplier(supplierUuid);
      await dispatch(fetchAllSuppliers());
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Import suppliers
export const importSuppliers = createAsyncThunk<
  ImportSupplierResponse,
  ImportSupplierArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'supplier/importSuppliers',
  async ({ attachment }, { dispatch, rejectWithValue }) => {
    try {
      const response = await ImportSuppliers(attachment);
      await dispatch(fetchAllSuppliers());
      return response;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }
      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }
    }
    return rejectWithValue({ message: 'An unknown error occurred' });
  },
);

// Export suppliers
export const exportSuppliers = createAsyncThunk<
  ExportSuppliersResponse,
  void,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>('supplier/exportSuppliers', async (_, { dispatch, rejectWithValue }) => {
  try {
    const response = await ExportSuppliers();
    return response;
  } catch (error: any) {
    if (error.response) {
      const { message, messages } = error.response.data;
      return rejectWithValue({ message, messages });
    }
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }
  }
  return rejectWithValue({ message: 'An unknown error occurred' });
});
