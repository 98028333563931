import axios, { AxiosHeaders, AxiosInstance } from 'axios';
import { getDeviceId } from '../auth/deviceId';

const axiosInstance: AxiosInstance = axios.create({
  baseURL: process.env.NEXT_PUBLIC_API_URL,
  headers: new AxiosHeaders({
    Accept: 'application/json',
    'Content-Type': 'application/json',
    'X-Requested-With': 'XMLHttpRequest',
    'Api-Key':
      'RyQn3TnPtESfMOEQ7iv6hlcBQMAhI5cdEWJayXCgjI99BzARldS681Ra3mikTkFt',
    'Device-Id': getDeviceId(),
    OS: 'windows',
    'App-Version': '1.0.0',
  }),
});

export default axiosInstance;
