import { apiCall } from '@/utility/api/apiUtils';
import { API_AUTH_LOGOUT_URL, API_METHOD_POST } from '@/constants/apiConstants';

export const logoutUser = async () => {
  try {
    const responseData = await apiCall({
      method: API_METHOD_POST,
      url: API_AUTH_LOGOUT_URL,
    });
    return responseData;
  } catch (error) {
    throw error;
  }
};
