import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface Breadcrumb {
  name: string;
  path: string;
}

interface BreadcrumbState {
  breadcrumbs: Breadcrumb[];
}

const initialState: BreadcrumbState = { breadcrumbs: [] };
const breadcrumbSlice = createSlice({
  name: 'breadcrumb',
  initialState,
  reducers: {
    setBreadcrumbs: (state, action: PayloadAction<Breadcrumb[]>) => {
      state.breadcrumbs = action.payload;
    },
    addBreadcrumb: (state, action: PayloadAction<Breadcrumb>) => {
      state.breadcrumbs.push(action.payload);
    },
    resetBreadcrumbs(state) {
      state.breadcrumbs = [];
    },
  },
});
export const { setBreadcrumbs, addBreadcrumb, resetBreadcrumbs } =
  breadcrumbSlice.actions;
export default breadcrumbSlice.reducer;
