import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';
import { CustomerSite } from '@/helpers/types/customerSiteTypes';
import { GetCustomerSitesData } from '@/api/customers/getCustomerSites'; // Adjust paths as necessary

// Define the type for the API response
interface CustomerSiteResponse {
  data: CustomerSite[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchCustomerSitesResponse = {
  data: CustomerSite[];
  pagination: Pagination;
};

type FetchCustomerSitesError = string | SerializedError;

// Thunk to fetch customer-specific sites
export const fetchCustomerSites = createAsyncThunk<
  FetchCustomerSitesResponse, // Return type
  number, // Argument type (customerId)
  {
    rejectValue: FetchCustomerSitesError;
  }
>('customer/fetchCustomerSites', async (customerId, { rejectWithValue }) => {
  try {
    // Pass customerId to your API function
    const response = await GetCustomerSitesData(customerId);
    const data: CustomerSiteResponse = response.data; // Ensure API response matches this structure
    return data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message); // Use the error message as the reject value
    }
    return rejectWithValue('An unknown error occurred');
  }
});
