/* eslint-disable prettier/prettier */
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchCustomerPortalProfile } from '@/redux/thunk/customer-portal/customerThunk';
import { CustomerPortalProfileType } from '@/helpers/types/customerPortalType';

interface CustomerPortalProfileState {
    customerPortalProfile: CustomerPortalProfileType;
    status: 'idle' | 'loading' | 'succeeded' | 'failed';
    error: string | null;
    fieldErrors?: Record<string, string[]>;
}

const initialState: CustomerPortalProfileState = {
    customerPortalProfile: {
        uuid: '',
        title: '',
        name: '',
        company_name: '',
        company: {
            id: '',
            name: ''
        },
        module_counts: {
            sites: 0,
            ongoing_jobs: 0,
            enquiries: 0,
            quotes: 0,
            jobs: 0,
            invoices: 0,
            notes: 0
        },
        email: '',
        phone: '',
        referred_by: '',
        is_active: 0,
        is_active_label: ''
    },
    status: 'idle',
    error: null,
    fieldErrors: {},
};

const customerPortalProfileSlice = createSlice({
    name: 'CustomerPortalProfile',
    initialState,
    reducers: {
        clearCustomerPortalProfile(state) {
            state.customerPortalProfile = initialState.customerPortalProfile;
        },
    },
    extraReducers: (builder) => {
        builder
            .addCase(fetchCustomerPortalProfile.pending, (state) => {
                state.status = 'loading';
                state.error = null;
            })
            .addCase(
                fetchCustomerPortalProfile.fulfilled,
                (
                    state,
                    action: PayloadAction<{ data: CustomerPortalProfileType }>,
                ) => {
                    state.customerPortalProfile = action.payload.data;
                    state.status = 'succeeded';
                },
            )
            .addCase(fetchCustomerPortalProfile.rejected, (state, action) => {
                state.status = 'failed';
                state.error = action.payload as string;
            });
    },
});

export const { clearCustomerPortalProfile } = customerPortalProfileSlice.actions;
export default customerPortalProfileSlice.reducer;
