import { API_METHOD_POST, API_SUPPLIER } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const ImportSuppliers = async (data: string) => {
  const method: Method = API_METHOD_POST;
  const url = API_SUPPLIER + '/import';

  const importSupplierData = {
    attachment: data,
  };

  const jsonString = JSON.stringify(importSupplierData);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data: importSupplierData,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
