import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { AccountBilling, Pagination } from '@/helpers/types';
import {
  fetchAccountBillings,
  searchAccountBillings,
} from '@/redux/thunk/accountBillingThunk';

interface AccountBillingState {
  accountBilling: AccountBilling[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: AccountBillingState = {
  accountBilling: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const accountBillingSlice = createSlice({
  name: 'accountBillings',
  initialState,
  reducers: {
    clearAccountBillings(state) {
      state.pagination = initialState.pagination;
      state.accountBilling = initialState.accountBilling;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAccountBillings.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAccountBillings.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AccountBilling[];
            pagination: Pagination;
          }>,
        ) => {
          state.accountBilling = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchAccountBillings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    builder
      .addCase(searchAccountBillings.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchAccountBillings.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AccountBilling[];
            pagination: Pagination;
          }>,
        ) => {
          state.accountBilling = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchAccountBillings.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearAccountBillings } = accountBillingSlice.actions;
export default accountBillingSlice.reducer;
