import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Job } from '@/helpers/types';
import { SearchJobsData } from '@/api/job/getScheduledJobs';
import { DateValue } from '@internationalized/date';

type FetchJobsResponse = {
  data: Job[];
};

type SearchJobsArgs = {
  searchTerm: string | null;
  startDate?: string | null | DateValue;
  endDate?: string | null | DateValue;
};

type FetchJobsError = string | SerializedError;

export const getJobs = createAsyncThunk<
  FetchJobsResponse,
  SearchJobsArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchJobsError;
  }
>(
  'jobScheduleCalender/getJobs',
  async ({ searchTerm, startDate, endDate }, { rejectWithValue }) => {
    try {
      const response = await SearchJobsData(searchTerm, startDate, endDate); // Pass both parameters
      const data: FetchJobsResponse = response.data;

      return { data: data.data }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
