import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { JobNotes, Pagination } from '@/helpers/types';
import {
  PurchaseOrderFormData,
  PurchaseOrderNotes,
  PurchaseOrders,
  PurchaseOrderStatusFormData,
  SinglePurchaseOrderByUuid,
} from '@/helpers/types/puchaseOrderTypes';
import { GetPurchaseOrdersData } from '@/api/purchaseOrder/getPurchaseOrders';
import { FetchPurchaseOrderByUuid } from '@/api/purchaseOrder/fetchPurchaseOrderByUuid';
import { CreatePurchaseOrder } from '@/api/purchaseOrder/createPurchaseOrder';
import { toast } from 'react-toastify';
import { UpdatePurchaseOrder } from '@/api/purchaseOrder/updatePurchaseOrder';
import { SearchPurchaseOrdersData } from '@/api/purchaseOrder/searchPurchaseOrders';
import { DeletePurchaseOrderData } from '@/api/purchaseOrder/deletePurchaseOrder';
import { UpdatePurchaseOrderStatus } from '@/api/purchaseOrder/updatePurchaseOrderStatus';
import { GetPurchaseOrderNotesData } from '@/api/purchaseOrder/getPurchaseOrderNotes';
import { fetchJobNotes, fetchJobs } from '@/redux/thunk/jobThunk';
import { AddPurchaseOrderNoteData } from '@/api/purchaseOrder/addPurchaseOrderNote';
import { UpdatePurchaseOrderNoteData } from '@/api/purchaseOrder/updatePurchaseOrderNote';

// Define the type for the API response
interface PurchaseOrderResponse {
  data: PurchaseOrders[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchPurchaseOrderResponse = {
  data: PurchaseOrders[];
  pagination: Pagination;
};

type SearchPurchaseOrdersArgs = {
  searchTerm: string;
  pageSize: string;
  pagination: number;
  selectedStatus?: string;
};

type FetchPurchaseOrderByUuidResponse = {
  data: SinglePurchaseOrderByUuid;
};

interface CreatePurchaseOrderResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

interface UpdatePurchaseOrderResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

type FetchPurchaseOrderNoteResponse = {
  data: PurchaseOrderNotes[];
};

type AddPurchaseOrderNoteResponse = {
  data: {
    uuid: string;
    note: string;
    attachment: null | string;
  };
  message: string;
};

type updatePurchaseOrderNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
  purchaseOrderNoteUuid: string;
  created_at: string;
};

type FetchPurchaseOrderByUuidArgs = string;
type FetchPurchaseOrderNotesByUuidArgs = string;

type FetchPurchaseOrdersError = string | SerializedError;
type CreatePurchaseOrdersError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};
type UpdatePurchaseOrdersError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};

type CreatePurchaseOrderArgs = PurchaseOrderFormData;

type UpdatePurchaseOrderArgs = PurchaseOrderFormData;

type DeletePurchaseOrderArgs = string;

type AddPurchaseOrderNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
};

interface UpdatePurchaseOrderStatusArgs extends PurchaseOrderStatusFormData {}

// type DuplicatePurchaseOrderArgs = QuoteDuplicateData;

export const fetchPurchaseOrders = createAsyncThunk<
  FetchPurchaseOrderResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchPurchaseOrdersError; // Type for rejected value
  }
>('purchaseOrder/fetchPurchaseOrders', async (_, { rejectWithValue }) => {
  try {
    const response = await GetPurchaseOrdersData();
    const data: PurchaseOrderResponse = response?.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchPurchaseOrders = createAsyncThunk<
  FetchPurchaseOrderResponse,
  SearchPurchaseOrdersArgs, // Updated to use the new argument structure
  {
    rejectValue: FetchPurchaseOrdersError;
  }
>(
  'purchaseOrder/searchPurchaseOrders',
  async (
    { searchTerm, pageSize, pagination, selectedStatus },
    { rejectWithValue },
  ) => {
    // Destructure the argument
    try {
      const response = await SearchPurchaseOrdersData(
        searchTerm,
        pageSize,
        pagination,
        selectedStatus,
      );
      const data: PurchaseOrderResponse = response.data;
      return { data: data.data, pagination: data.pagination }; // Return formatted data
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deletePurchaseOrder = createAsyncThunk<
  void, // Return type of the payload creator
  DeletePurchaseOrderArgs, // Argument type of the payload creator
  {
    rejectValue: FetchPurchaseOrdersError; // Type for rejected value
  }
>(
  'purchaseOrder/deletePurchaseOrder',
  async (purchaseOrderUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the purchase order
      await DeletePurchaseOrderData(purchaseOrderUuId);
      toast.success('Purchase order deleted successfully!');
      // After successful deletion, dispatch fetchTeamMembers to refresh the list
      dispatch(fetchPurchaseOrders());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchPurchaseOrderByUuid = createAsyncThunk<
  FetchPurchaseOrderByUuidResponse,
  FetchPurchaseOrderByUuidArgs,
  {
    rejectValue: FetchPurchaseOrdersError;
  }
>(
  'purchaseOrder/fetchPurchaseOrderByUuid',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const response = await FetchPurchaseOrderByUuid(uuid);
      const data: SinglePurchaseOrderByUuid = response?.data;
      return { data: data };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

// Thunk for creating a purchase order
export const createPurchaseOrder = createAsyncThunk<
  CreatePurchaseOrderResponse, // Success response type
  CreatePurchaseOrderArgs, // Request payload type
  { rejectValue: CreatePurchaseOrdersError } // Error type for rejection
>(
  'purchaseOrder/createPurchaseOrder',
  async (
    purchaseOrderData: CreatePurchaseOrderArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await CreatePurchaseOrder(purchaseOrderData);
      toast.success('Purchase Order added successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchPurchaseOrders());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

// Thunk for update a purchase order
export const updatePurchaseOrder = createAsyncThunk<
  UpdatePurchaseOrderResponse, // Success response type
  UpdatePurchaseOrderArgs, // Request payload type
  { rejectValue: UpdatePurchaseOrdersError } // Error type for rejection
>(
  'quote/updateQuote',
  async (
    purchaseOrderData: UpdatePurchaseOrderArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await UpdatePurchaseOrder(purchaseOrderData);
      toast.success('Purchase Order updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchPurchaseOrders());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const updatePurchaseOrderStatus = createAsyncThunk<
  UpdatePurchaseOrderResponse, // Success response type
  UpdatePurchaseOrderStatusArgs, // Request payload type
  { rejectValue: UpdatePurchaseOrdersError } // Error type for rejection
>(
  'purchaseOrder/updatePurchaseOrderStatus',
  async (
    purchaseOrderData: UpdatePurchaseOrderStatusArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await UpdatePurchaseOrderStatus(purchaseOrderData);
      toast.success('Purchase Order status updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchPurchaseOrders());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const fetchPurchaseOrderNotes = createAsyncThunk<
  FetchPurchaseOrderNoteResponse, // Return type of the payload creator
  FetchPurchaseOrderNotesByUuidArgs, // Argument type of the payload creator
  {
    rejectValue: FetchPurchaseOrdersError; // Type for rejected value
  }
>(
  'purchaseOrderNotes/fetchPurchaseOrderNotes',
  async (uuid: string, { rejectWithValue }) => {
    try {
      const response = await GetPurchaseOrderNotesData(uuid);
      const data: JobNotes[] = response?.data;
      return { data: data }; // Return formatted data
    } catch (error) {
      // Type guard to narrow down error to Error type
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      // Fallback for unknown error types
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const addPurchaseOrderNote = createAsyncThunk<
  AddPurchaseOrderNoteResponse,
  AddPurchaseOrderNoteArgs,
  {
    rejectValue: FetchPurchaseOrdersError;
  }
>(
  'purchaseOrderNotes/addPurchaseOrderNote',
  async ({ uuid, note, attachment }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddPurchaseOrderNoteData(uuid, note, attachment);
      const data: AddPurchaseOrderNoteResponse = response;

      dispatch(fetchPurchaseOrders());
      dispatch(fetchPurchaseOrderNotes(uuid));
      // Ensure you return an object with a 'message' field
      return {
        data: data.data,
        message: data.message || 'Note added successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);

export const updatePurchaseOrderNote = createAsyncThunk<
  AddPurchaseOrderNoteResponse,
  updatePurchaseOrderNoteArgs,
  {
    rejectValue: FetchPurchaseOrdersError;
  }
>(
  'purchaseOrderNotes/updatePurchaseOrderNote',
  async (
    { uuid, note, attachment, purchaseOrderNoteUuid, created_at },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await UpdatePurchaseOrderNoteData(
        uuid,
        note,
        attachment,
        purchaseOrderNoteUuid,
        created_at,
      );
      const data: AddPurchaseOrderNoteResponse = response;

      dispatch(fetchPurchaseOrders());
      // Ensure you return an object with a 'message' field
      return {
        data: data.data,
        message: data.message || 'Note updated successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);
