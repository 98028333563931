import { JobStatusData } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetJobStatus } from '@/api/job/getJobStatus';

type FetchJobStatusResponse = {
  data: JobStatusData[];
};

type FetchJobStatusError = string | SerializedError;

// Fetch job status
export const fetchJobStatus = createAsyncThunk<
  FetchJobStatusResponse,
  void,
  { rejectValue: FetchJobStatusError }
>('jobStatus/fetchJobStatus', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobStatus();
    const data: FetchJobStatusResponse = response;
    return data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
