'use client';
// Import the functions you need from the SDKs you need
import { initializeApp } from 'firebase/app';
import { getMessaging, getToken } from 'firebase/messaging';

export const Firebase = async () => {
  const permission = await Notification.requestPermission();
  if (permission === 'granted') {
    if (typeof window !== 'undefined' && 'serviceWorker' in navigator) {
      const firebaseConfig = {
        apiKey: 'AIzaSyDOWhAJiSPtLflObstT_XpfusC0r4aRH2U',
        authDomain: 'zynoff-development.firebaseapp.com',
        projectId: 'zynoff-development',
        storageBucket: 'zynoff-development.firebasestorage.app',
        messagingSenderId: '766913897126',
        appId: '1:766913897126:web:d06ca3f1992dd1552b0a67',
        measurementId: 'G-FQMRMJ4JSN',
      };

      // Initialize Firebase
      // const analytics = getAnalytics(app);
      const app = initializeApp(firebaseConfig);
      const messaging = getMessaging(app);
      try {
        return await getToken(messaging, {
          vapidKey:
            'BOXWYTk-sVdGvjKPlf6tZmt-Vc97472CGbW8vKI0__JUIG2-OuREp92mAN3DMhbDHj8b56DquOAIqmJm1-fhH_s',
        });
      } catch (error) {
        console.error('Error retrieving token:', error);
        return null;
      }
    } else {
      console.log('Unable to get permission to notify.');
      return null;
    }
  }
};
