import { API_METHOD_GET, API_PRODUCTS_EXPORT } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const ExportProducts = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_PRODUCTS_EXPORT;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
