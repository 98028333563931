import React from 'react';

export const ExportIcon = ({ color = 'white' }) => {
  return (
    <svg
      width="15"
      height="18"
      viewBox="0 0 15 18"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M7.49996 9V14M7.49996 14L9.99996 12.3333M7.49996 14L4.99996 12.3333M8.33329 1.50072C8.25371 1.5 8.16436 1.5 8.06222 1.5H4.33346C3.40003 1.5 2.93298 1.5 2.57646 1.68166C2.26285 1.84144 2.00807 2.09623 1.84828 2.40983C1.66663 2.76635 1.66663 3.23341 1.66663 4.16683V13.8335C1.66663 14.7669 1.66663 15.2334 1.84828 15.5899C2.00807 15.9035 2.26285 16.1587 2.57646 16.3185C2.93263 16.5 3.39912 16.5 4.33072 16.5H10.6692C11.6008 16.5 12.0666 16.5 12.4228 16.3185C12.7364 16.1587 12.992 15.9035 13.1518 15.5899C13.3333 15.2337 13.3333 14.7679 13.3333 13.8363V6.7714C13.3333 6.66913 13.3333 6.57967 13.3325 6.5M8.33329 1.50072C8.57132 1.50289 8.72191 1.51154 8.86566 1.54605C9.03572 1.58688 9.19817 1.65439 9.34729 1.74577C9.51543 1.8488 9.65981 1.99318 9.94788 2.28125L12.5524 4.88582C12.8407 5.17407 12.984 5.3178 13.0871 5.48599C13.1785 5.63511 13.2461 5.79771 13.2869 5.96777C13.3214 6.11147 13.3302 6.26209 13.3325 6.5M8.33329 1.50072V3.83333C8.33329 4.76675 8.33329 5.23314 8.51495 5.58966C8.67474 5.90326 8.92952 6.15873 9.24312 6.31852C9.59929 6.5 10.0658 6.5 10.9974 6.5H13.3325M13.3325 6.5H13.3335"
        stroke={color}
        strokeWidth={2}
        strokeLinecap={'round'}
        strokeLinejoin={'round'}
      />
    </svg>
  );
};
