import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_EMAIL_TEMPLATE_SETTINGS,
  API_METHOD_GET,
} from '@/constants/apiConstants';

export const GetEmailTemplateSettings = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_EMAIL_TEMPLATE_SETTINGS;

  try {
    return await apiCall({
      method,
      url,
    });
  } catch (error) {
    throw error;
  }
};
