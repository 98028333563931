import { Skeleton } from '@nextui-org/react';
import React from 'react';

const MapSkeleton: React.FC<{ id: string }> = ({ id }) => {
  return (
    <div id={id}>
      <Skeleton className="w-full rounded-lg">
        <div
          style={{ height: 'calc(100vh - 308px' }}
          className="bg-default-300 rounded-lg"
        ></div>
      </Skeleton>
    </div>
  );
};

export default MapSkeleton;
