// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_GET_QUOTES, API_METHOD_POST } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const AddQuoteNoteData = async (
  uuid: string,
  note: string,
  attachment: string,
) => {
  const method: Method = API_METHOD_POST;

  const url = API_GET_QUOTES + `/${uuid}/notes`;

  const data = {
    note: note,
    attachment: attachment,
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };
  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
