import { Navbar, NavbarContent } from '@nextui-org/react';
import React from 'react';
import { BurguerButton } from './burguer-button';
import NotificationsDropdown from './notificationsDropdown';
import { UserDropdown } from './userDropdown';
import Image from 'next/image';
import logoWhite from '../../../public/assets/images/logo-new-white.svg';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import Link from 'next/link';

interface Props {
  children: React.ReactNode;
  src?: 'customer-portal' | null;
}

export const NavbarWrapper = ({ children }: Props) => {
  const { user } = useSelector((state: RootState) => state.auth);
  const currentDate = new Date();

  // Arrays for weekdays and months
  const weekdays: string[] = [
    'Sunday',
    'Monday',
    'Tuesday',
    'Wednesday',
    'Thursday',
    'Friday',
    'Saturday',
  ];
  const months: string[] = [
    'January',
    'February',
    'March',
    'April',
    'May',
    'June',
    'July',
    'August',
    'September',
    'October',
    'November',
    'December',
  ];

  // Get day and determine ordinal suffix
  const day = currentDate.getDate();
  const ordinalSuffix = (day: number): string => {
    if (day >= 11 && day <= 13) return 'th';
    switch (day % 10) {
      case 1:
        return 'st';
      case 2:
        return 'nd';
      case 3:
        return 'rd';
      default:
        return 'th';
    }
  };

  return (
    <div className="relative flex flex-col flex-1 z-10">
      <div style={{ backgroundColor: '#f3f8ff' }} className="z-10 shadow-sm">
        <Navbar
          height="70px"
          isBordered
          className="w-full flex justify-center items-center fixed right-0 bg-primary z-10 shadow-sm"
          classNames={{
            wrapper: 'w-full max-w-full',
          }}
          // style={{ backgroundColor: '#F3F8FF' }}
        >
          <NavbarContent className="mr-3 md:hidden">
            <BurguerButton />
          </NavbarContent>
          {/* <NavbarContent className="w-full max-md:hidden"> */}
          <div className="pl-[45px]">
            <Link href="/">
              <Image
                src={logoWhite}
                alt="logo"
                width={150}
                priority
                className="cursor-pointer"
              />
            </Link>
          </div>
          {/* </NavbarContent> */}
          {/* <NavbarContent className="hidden md:flex flex justify-start"> */}

          <div className="text-sm md:text-xl font-medium text-white">
            {user?.company_name ? user?.company_name + ' - ' : ''}
            {weekdays[currentDate.getDay()]}, {months[currentDate.getMonth()]}{' '}
            {day}
            <sup>{ordinalSuffix(day)}</sup>, {currentDate.getFullYear()}
          </div>
          {/* </NavbarContent> */}

          <NavbarContent
            justify="end"
            className="flex w-fit data-[justify=end]:flex-grow-0 gap-4"
          >
            <div className="flex gap-4 md:gap-10 pr-[55px]">
              <NotificationsDropdown />
              <NavbarContent>
                <UserDropdown />
              </NavbarContent>
            </div>
          </NavbarContent>
        </Navbar>
      </div>
      <div className="px-4 py-4 lg:px-8 lg:py-6 w-full mt-[70px]">
        {children}
      </div>
    </div>
  );
};
