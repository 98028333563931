import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { PurchaseOrderNotes } from '@/helpers/types/puchaseOrderTypes';
import { fetchPurchaseOrderNotes } from '@/redux/thunk/purchaseOderThunk';

interface PurchaseOrderNotesState {
  purchaseOrderNotes: PurchaseOrderNotes[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PurchaseOrderNotesState = {
  purchaseOrderNotes: [],
  status: 'idle',
  error: null,
};

const purchaseOrderNotesSlice = createSlice({
  name: 'purchaseOrderNotes',
  initialState,
  reducers: {
    clearPurchaseOrderNotes(state) {
      state.purchaseOrderNotes = initialState.purchaseOrderNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPurchaseOrderNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchPurchaseOrderNotes.fulfilled,
        (state, action: PayloadAction<{ data: PurchaseOrderNotes[] }>) => {
          state.purchaseOrderNotes = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchPurchaseOrderNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearPurchaseOrderNotes } = purchaseOrderNotesSlice.actions;
export default purchaseOrderNotesSlice.reducer;
