import { JobStatusData } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobStatus } from '@/redux/thunk/jobStatusThunk';
import {
  PurchaseOrders,
  PurchaseOrderStatusData,
} from '@/helpers/types/puchaseOrderTypes';
import { fetchPurchaseOrderStatus } from '@/redux/thunk/purchaseOrderStatusThunk';

interface PurchaseOrderStatusState {
  purchaseOrderStatus: PurchaseOrderStatusData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: PurchaseOrderStatusState = {
  purchaseOrderStatus: [],
  status: 'idle',
  error: null,
};

const purchaseOrderStatusSlice = createSlice({
  name: 'purchase-order-state',
  initialState,
  reducers: {
    clearPurchaseOrderStatus(state) {
      state.purchaseOrderStatus = initialState.purchaseOrderStatus;
    },
  },
  extraReducers: (builder) => {
    // Fetch enquiry status
    builder
      .addCase(fetchPurchaseOrderStatus.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchPurchaseOrderStatus.fulfilled,
        (state, action: PayloadAction<{ data: PurchaseOrderStatusData[] }>) => {
          state.status = 'succeeded';
          state.purchaseOrderStatus = action.payload.data;
        },
      )
      .addCase(fetchPurchaseOrderStatus.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearPurchaseOrderStatus } = purchaseOrderStatusSlice.actions;
export default purchaseOrderStatusSlice.reducer;
