import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_EMAIL_TEMPLATE, API_METHOD_GET } from '@/constants/apiConstants';

export const GetEmailTemplate = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_EMAIL_TEMPLATE;

  try {
    return await apiCall({
      method,
      url,
    });
  } catch (error) {
    throw error;
  }
};
