import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { Pagination, Team, TeamMember } from '@/helpers/types';
import {
  addTeamMember,
  fetchTeamMemberById,
  fetchTeamMembers,
  searchTeamMembers,
  updateTeamMember,
} from '../thunk/teamThunk';

interface TeamState {
  team: Team[];
  teamMember: TeamMember | null; // Add to store the individual team member
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: TeamState = {
  team: [],
  teamMember: null, // Initialize as null for a single team member
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const teamSlice = createSlice({
  name: 'team',
  initialState,
  reducers: {
    clearTeams(state) {
      state.pagination = initialState.pagination;
      state.team = initialState.team;
      state.teamMember = initialState.teamMember; // Clear individual team member
    },
  },
  extraReducers: (builder) => {
    // Handle fetchTeamMembers (existing functionality)
    builder
      .addCase(fetchTeamMembers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchTeamMembers.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Team[]; pagination: Pagination }>,
        ) => {
          state.team = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchTeamMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Handle searchTeamMembers (existing functionality)
    builder
      .addCase(searchTeamMembers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchTeamMembers.fulfilled,
        (
          state,
          action: PayloadAction<{ data: Team[]; pagination: Pagination }>,
        ) => {
          state.team = action.payload.data;
          state.pagination = action.payload.pagination;
          state.status = 'succeeded';
        },
      )
      .addCase(searchTeamMembers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Handle fetchTeamMemberById (new functionality)
    builder
      .addCase(fetchTeamMemberById.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchTeamMemberById.fulfilled,
        (state, action: PayloadAction<{ data: TeamMember }>) => {
          state.teamMember = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchTeamMemberById.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Handle addTeamMember
    builder
      .addCase(addTeamMember.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        addTeamMember.fulfilled,
        (state, action: PayloadAction<Team>) => {
          state.team.push(action.payload); // Add the new team member to the team array
          state.status = 'succeeded';
          state.error = null; // Clear any previous errors
          state.fieldErrors = {}; // Clear field errors on success
        },
      )
      .addCase(addTeamMember.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message; // General error message
          state.fieldErrors = action.payload.messages || {}; // Field-specific errors
        } else {
          state.error = 'An unknown error occurred'; // Fallback error message
        }
      });

    // Handle Update Team Member
    builder
      .addCase(updateTeamMember.pending, (state) => {
        state.status = 'loading';
        state.error = null;
        state.fieldErrors = {};
      })
      .addCase(
        updateTeamMember.fulfilled,
        (state, action: PayloadAction<Team>) => {
          // Find the index of the team member to update
          const index = state.team.findIndex(
            (member) => member.uuid === action.payload.uuid,
          );

          // Update the team member if found
          if (index !== -1) {
            state.team[index] = action.payload; // Replace the existing member with the updated member
          }

          state.status = 'succeeded';
          state.error = null; // Clear any previous errors
          state.fieldErrors = {}; // Clear field errors on success
        },
      )
      .addCase(updateTeamMember.rejected, (state, action) => {
        state.status = 'failed';
        if (action.payload) {
          state.error = action.payload.message; // General error message
          state.fieldErrors = action.payload.messages || {}; // Field-specific errors
        } else {
          state.error = 'An unknown error occurred'; // Fallback error message
        }
      });
  },
});

export const { clearTeams } = teamSlice.actions;
export default teamSlice.reducer;
