import { API_INVOICE, API_METHOD_PUT } from '@/constants/apiConstants';
import { UpdatedStatusChangeFormData } from '@/redux/thunk/invoiceThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const ChangeStatus = async (
    updatedStatusData: UpdatedStatusChangeFormData,
) => {
    const method: Method = API_METHOD_PUT;
    const url = API_INVOICE + '/' + updatedStatusData.invoiceUuid + '/status';
    const data = {
        status: updatedStatusData.status,
    };

    const jsonString = JSON.stringify(data);
    const additionalHeaders = { hash: generateHash(jsonString) };

    try {
        const response = await apiCall({
            method,
            url,
            data,
            headers: additionalHeaders,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
