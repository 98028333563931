// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_GET_QUOTES, API_METHOD_GET } from '@/constants/apiConstants';

export const SearchQuotesData = async (
  searchTerm: string,
  status?: number,
  pageSize?: string,
  pagination?: number,
  startDate?: string,
  endDate?: string,
  selectedCustomer?: string,
) => {
  const method: Method = API_METHOD_GET;
  const url =
    API_GET_QUOTES +
    `?search=${searchTerm}` +
    (status !== 100 ? `&f-status=${status}` : '') +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`) +
    (startDate && startDate !== 'undefined-undefined-undefined'
      ? `&f-created_at=${startDate}:${endDate}`
      : '') +
    (selectedCustomer && selectedCustomer !== 'undefined-undefined-undefined'
      ? `&f-customer=${encodeURIComponent(`${selectedCustomer}`)}`
      : '');

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
