import { AllSuppliersType, Pagination } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchAllSuppliers, searchSupplier } from '../thunk/supplierThunk';

interface SupplierAllState {
  suppliers: AllSuppliersType[];
  pagination: Pagination;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: SupplierAllState = {
  suppliers: [],
  pagination: {
    total_records: 0,
    current_records: 0,
    record_from: 0,
    record_to: 0,
    current_page: 0,
    total_pages: 0,
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const supplierAllSlice = createSlice({
  name: 'supplier-all',
  initialState,
  reducers: {
    clearSuppliers(state) {
      state.suppliers = initialState.suppliers;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchAllSuppliers.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchAllSuppliers.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllSuppliersType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.suppliers = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      )
      .addCase(fetchAllSuppliers.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });

    // Search supplier
    builder
      .addCase(searchSupplier.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        searchSupplier.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: AllSuppliersType[];
            pagination: Pagination;
          }>,
        ) => {
          state.status = 'succeeded';
          state.suppliers = action.payload.data;
          state.pagination = action.payload.pagination;
        },
      );
  },
});

export const { clearSuppliers } = supplierAllSlice.actions;
export default supplierAllSlice.reducer;
