import { BottomNavItem } from './bottomnav-item';
import { NAV_ITEMS } from './data';

export const BottomNavMenu = () => {
  return (
    <div className="gap-2 px-2 md:gap-3 lg:gap-5 flex justify-center flex-wrap bg-light-primary-100 pb-5 pt-5">
      {NAV_ITEMS.map((nav_item) => (
        <BottomNavItem
          key={nav_item.title}
          icon={nav_item.icon}
          title={nav_item.title}
          path={nav_item.path}
          href={nav_item.href}
        />
      ))}
    </div>
  );
};
