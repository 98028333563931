import { API_METHOD_GET, API_SUPPLIER } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const ExportSuppliers = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_SUPPLIER + '/export';

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
