import { SupplierNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSupplierSingleNote } from '../thunk/supplierNoteThunk';

interface SupplierSingleNoteState {
  supplierNote: SupplierNoteType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: SupplierSingleNoteState = {
  supplierNote: {
    uuid: '',
    note: '',
    attachment: {
      link: '',
      size: '',
      name: '',
    },
    created_at: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const supplierSingleNoteSlice = createSlice({
  name: 'supplier-single-note-state',
  initialState,
  reducers: {
    clearSupplierNote(state) {
      state.supplierNote = initialState.supplierNote;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierSingleNote.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchSupplierSingleNote.fulfilled,
        (state, action: PayloadAction<SupplierNoteType>) => {
          state.status = 'succeeded';
          state.supplierNote = action.payload;
        },
      )
      .addCase(fetchSupplierSingleNote.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSupplierNote } = supplierSingleNoteSlice.actions;
export default supplierSingleNoteSlice.reducer;
