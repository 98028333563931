import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetEmailTemplateSettings } from '@/api/emailTemplate/getEmailTemplateSettings';
import { GetEmailTemplateSenderList } from '@/api/emailTemplate/getEmailTemplateSenderList';
import {
  EmailSenderList,
  EmailTemplate,
  EmailTemplateDataTable,
} from '@/helpers/types';
import { toast } from 'react-toastify';
import { SetEmailTemplateSettings } from '@/api/emailTemplate/setEmailTemplateSettings';
import { GetEmailTemplate } from '@/api/emailTemplate/getEmailTemplate';
import { SearchEmailTemplates } from '@/api/emailTemplate/searchEmailTemplates';
import { GetEmailTemplateById } from '@/api/emailTemplate/getEmailTemplateById';
import { UpdateEmailTemplate } from '@/api/emailTemplate/updateEmailTemplate';

type FetchError = string | SerializedError;

type FetchEmailTemplateSettingsResponse = {
  email_sender: string;
};

type SetEmailTemplateSettingsRequest = {
  email_sender: string;
};

type FetchEmailTemplateSenderListResponse = {
  data: EmailSenderList[];
};

type FetchEmailTemplatesResponse = {
  data: EmailTemplateDataTable[];
};

type SearchEmailTemplateArgs = {
  searchTerm: string;
};

type updateEmailTemplateArgs = {
  templateCode: string;
  payload: {
    body: string;
  };
};
export const fetchEmailTemplateSettings = createAsyncThunk<
  FetchEmailTemplateSettingsResponse,
  void,
  {
    rejectValue: FetchError;
  }
>(
  'emailTemplateSettings/fetchEmailTemplateSettings',
  async (_, { rejectWithValue }) => {
    try {
      const response = await GetEmailTemplateSettings();
      const data: FetchEmailTemplateSettingsResponse = response.data;
      return data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchEmailTemplateSenderList = createAsyncThunk<
  FetchEmailTemplateSenderListResponse,
  void,
  {
    rejectValue: FetchError;
  }
>(
  'emailTemplateSenderList/fetchEmailTemplateSenderList',
  async (_, { rejectWithValue }) => {
    try {
      const data: FetchEmailTemplateSenderListResponse =
        await GetEmailTemplateSenderList();
      return data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const setEmailTemplateSettings = createAsyncThunk<
  void,
  SetEmailTemplateSettingsRequest,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'emailTemplateSettings/setEmailTemplateSettings',
  async (
    emailTemplateSettings: SetEmailTemplateSettingsRequest,
    { rejectWithValue, dispatch },
  ) => {
    try {
      const response = await SetEmailTemplateSettings(emailTemplateSettings);
      toast.success('Email sender set successfully!');
      dispatch(fetchEmailTemplateSettings());
      return response.data;
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);

export const fetchEmailTemplate = createAsyncThunk<
  FetchEmailTemplatesResponse,
  void,
  {
    rejectValue: FetchError;
  }
>('emailTemplate/fetchEmailTemplate', async (_, { rejectWithValue }) => {
  try {
    const data: FetchEmailTemplatesResponse = await GetEmailTemplate();
    return data;
  } catch (error: any) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchEmailTemplates = createAsyncThunk<
  FetchEmailTemplatesResponse,
  SearchEmailTemplateArgs,
  {
    rejectValue: FetchError;
  }
>(
  'emailTemplate/searchEmailTemplate',
  async ({ searchTerm }, { rejectWithValue }) => {
    try {
      const data: FetchEmailTemplatesResponse =
        await SearchEmailTemplates(searchTerm);
      return data;
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchEmailTemplateById = createAsyncThunk<
  EmailTemplate,
  string,
  {
    rejectValue: FetchError;
  }
>(
  'emailTemplate/fetchEmailTemplateById',
  async (templateCode: string, { rejectWithValue }) => {
    try {
      const response = await GetEmailTemplateById(templateCode);
      return response.data; // Return the single team member data
    } catch (error: any) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const updateEmailTemplate = createAsyncThunk<
  EmailTemplate,
  updateEmailTemplateArgs,
  { rejectValue: { message: string; messages?: Record<string, string[]> } }
>(
  'emailTemplate/updateEmailTemplate',
  async ({ templateCode, payload }, { rejectWithValue }) => {
    try {
      const response = await UpdateEmailTemplate(templateCode, payload); // Function to send a POST request
      toast.success(response.message);
      return response; // Return the created customer site
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data; // Adjust according to your API response structure
        return rejectWithValue({ message, messages });
      }

      if (error instanceof Error) {
        return rejectWithValue({ message: error.message });
      }

      return rejectWithValue({ message: 'An unknown error occurred' });
    }
  },
);
