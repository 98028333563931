import { API_METHOD_PUT, API_SUPPLIER } from '@/constants/apiConstants';
import { UpdateSupplierNoteData } from '@/redux/thunk/supplierNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateSupplierNote = async (
  updatedSupplierNoteData: UpdateSupplierNoteData,
) => {
  const method: Method = API_METHOD_PUT;
  const url =
    API_SUPPLIER +
    '/' +
    updatedSupplierNoteData.supplierUuid +
    '/' +
    'notes' +
    '/' +
    updatedSupplierNoteData.noteUuid;

  const data = {
    note: updatedSupplierNoteData.note,
    attachment: updatedSupplierNoteData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
