import { API_ENQUIRY, API_METHOD_POST } from '@/constants/apiConstants';
import { EnquiryType } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddEnquiry = async (enquiryValues: EnquiryType) => {
  const method: Method = API_METHOD_POST;
  const url = API_ENQUIRY;
  const data = {
    enquiry_number: enquiryValues.enquiry_number,
    customer_id: enquiryValues.customer_id,
    customer_site_id: enquiryValues.customer_site_id,
    title: enquiryValues.title,
    requested_on: enquiryValues.requested_on,
    details: enquiryValues.details,
    expected_date: enquiryValues.expected_date,
    expected_time: enquiryValues.expected_time,
    onsite_assessment_required: enquiryValues.onsite_assessment_required,
    assessment: {
      details: enquiryValues.assessment.details,
      start_date: enquiryValues.assessment.start_date,
      end_date: enquiryValues.assessment.end_date,
      start_time: enquiryValues.assessment.start_time,
      end_time: enquiryValues.assessment.end_time,
      assessmentMembers: enquiryValues.assessment.assessmentMembers,
      send_notification: enquiryValues.assessment.send_notification,
    },
    notes: enquiryValues.notes,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
