import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetPermissions } from '@/api/permission/getPermissions';

// Define the type for the API response
interface PermissionResponse {
  data: [];
}

export const fetchPermission = createAsyncThunk<
  PermissionResponse,
  void,
  {
    rejectValue: string | SerializedError; // The type for rejected value
  }
>('permission/fetchPermission', async (_, { rejectWithValue }) => {
  try {
    return await GetPermissions();
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
