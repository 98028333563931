import { API_METHOD_POST, API_SUPPLIER } from '@/constants/apiConstants';
import { AddSupplierNoteData } from '@/redux/thunk/supplierNoteThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const CreateSupplierNote = async (
  newSupplierNoteData: AddSupplierNoteData,
) => {
  const method: Method = API_METHOD_POST;
  const url = API_SUPPLIER + '/' + newSupplierNoteData.supplierUuid + '/notes';
  const data = {
    note: newSupplierNoteData.note,
    attachment: newSupplierNoteData.attachment,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
