import { API_METHOD_PUT, API_SUPPLIER } from '@/constants/apiConstants';
import { UpdatedSupplierFormData } from '@/redux/thunk/supplierThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const UpdateSupplier = async (
  updatedSupplierData: UpdatedSupplierFormData,
) => {
  const method: Method = API_METHOD_PUT;
  const url = API_SUPPLIER + '/' + updatedSupplierData.supplierUuid;
  const data = {
    is_active: updatedSupplierData.is_active,
    name: updatedSupplierData.name,
    contact_name: updatedSupplierData.contact_name,
    phone: updatedSupplierData.phone,
    email: updatedSupplierData.email,
    type: updatedSupplierData.type,
    address: {
      street_number: updatedSupplierData.address.street_number,
      street_name: updatedSupplierData.address.street_name,
      suburb: updatedSupplierData.address.suburb,
      city: updatedSupplierData.address.city,
      postal_code: updatedSupplierData.address.postal_code,
      region: updatedSupplierData.address.region,
      country: updatedSupplierData.address.country,
      latitude: updatedSupplierData.address.latitude,
      longitude: updatedSupplierData.address.longitude,
      search_keyword: updatedSupplierData.address.search_keyword,
    },
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
