import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Subscription, User } from '@/helpers/types';
import { loginUser } from '@/api/login';
import { logoutUser } from '@/api/logoutUser';
import { GetUser } from '@/api/getUser';

interface FetchLoginResponse {
  user: {
    first_name: string;
    last_name: string;
    email: string;
    phone: string | null;
    labor_cost: number | string | null;
    company_id: number | null;
    company_name: string | null;
    is_active: boolean | null;
    uuid: string;
    role: string;
    permissions: string[];
  } | null;
  access_token: {
    expires_at: string;
    token: string;
  } | null;
  refresh_token: {
    expires_at: string;
    token: string;
  } | null;
  subscription: Subscription;
}

interface FetchLogoutResponse {
  status: string;
  data: [];
  message: string;
}

type LoginArgs = {
  email: string;
  password: string;
};

type FetchUserDataError = {
  message: string;
  messages?: Record<string, string[]>;
};

type FetchUserResponse = {
  data: User;
};

type FetchError = string | SerializedError;

export const fetchLogin = createAsyncThunk<
  FetchLoginResponse, // Return type of the payload creator
  LoginArgs, // Argument type of the payload creator
  {
    rejectValue: FetchUserDataError; // Type for rejected value
  }
>('auth/fetchLogin', async ({ email, password }, { rejectWithValue }) => {
  try {
    // Call the login API to authenticate the user
    const response = await loginUser({ email, password });

    // Format and return the data accordingly
    const data: FetchLoginResponse = response?.data;

    return data;
  } catch (error: any) {
    // Check if the error is from Axios and has a response
    if (error.response) {
      // Extract error details from the response
      const { message, messages } = error.response.data;

      // Return a serialized error for Redux
      return rejectWithValue({
        message: message || 'An error occurred',
        messages,
      });
    }

    // For other types of errors, return a generic error
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }

    // Handle unknown errors
    return rejectWithValue({ message: 'An unknown error occurred' });
  }
});

export const fetchLogout = createAsyncThunk<
  FetchLogoutResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchUserDataError; // Type for rejected value
  }
>('auth/fetchLogout', async (_, { rejectWithValue }) => {
  try {
    // Call the login API to authenticate the user
    const response = await logoutUser();

    // Format and return the data accordingly
    const data: FetchLogoutResponse = response;

    return data;
  } catch (error: any) {
    // Check if the error is from Axios and has a response
    if (error.response) {
      // Extract error details from the response
      const { message, messages } = error.response.data;

      // Return a serialized error for Redux
      return rejectWithValue({
        message: message || 'An error occurred',
        messages,
      });
    }

    // For other types of errors, return a generic error
    if (error instanceof Error) {
      return rejectWithValue({ message: error.message });
    }

    // Handle unknown errors
    return rejectWithValue({ message: 'An unknown error occurred' });
  }
});

export const fetchUser = createAsyncThunk<
  FetchUserResponse,
  void,
  {
    rejectValue: FetchError;
  }
>('auth/fetchUser', async (_, { rejectWithValue }) => {
  try {
    const data: FetchUserResponse = await GetUser();

    return data;
  } catch (error: any) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
