import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_CALCULATE_FINANCES,
  API_METHOD_POST,
} from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const CalculateFinances = async (payload: any) => {
  const method: Method = API_METHOD_POST;
  const url = API_CALCULATE_FINANCES;
  const data = payload;
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };
  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
