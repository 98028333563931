import { SingleSupplierType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSingleSupplier } from '../thunk/supplierThunk';

interface SupplierSingleState {
  supplier: SingleSupplierType;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: SupplierSingleState = {
  supplier: {
    uuid: '',
    name: '',
    contact_name: '',
    phone: '',
    email: '',
    is_active: 0,
    address: {
      uuid: '',
      street_number: '',
      street_name: '',
      suburb: '',
      city: '',
      region: '',
      country: '',
      postal_code: '',
      longitude: '',
      latitude: '',
      search_keyword: '',
    },
    notes: [],
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const supplierSingleSlice = createSlice({
  name: 'supplier-single-state',
  initialState,
  reducers: {
    clearSupplier(state) {
      state.supplier = initialState.supplier;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSingleSupplier.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchSingleSupplier.fulfilled,
        (state, action: PayloadAction<SingleSupplierType>) => {
          state.status = 'succeeded';
          state.supplier = action.payload;
        },
      )
      .addCase(fetchSingleSupplier.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSupplier } = supplierSingleSlice.actions;
export default supplierSingleSlice.reducer;
