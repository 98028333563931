import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobNotes } from '../thunk/jobThunk';
import { JobNotes } from '@/helpers/types';

interface JobNotesState {
  jobNotes: JobNotes[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: JobNotesState = {
  jobNotes: [],
  status: 'idle',
  error: null,
};

const jobNotesSlice = createSlice({
  name: 'jobNotes',
  initialState,
  reducers: {
    clearJobNotes(state) {
      state.jobNotes = initialState.jobNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobNotes.fulfilled,
        (state, action: PayloadAction<{ data: JobNotes[] }>) => {
          state.jobNotes = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchJobNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJobNotes } = jobNotesSlice.actions;
export default jobNotesSlice.reducer;
