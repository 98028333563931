import { API_METHOD_PUT, API_SUPPLIER } from '@/constants/apiConstants';
import { SupplierStatusUpdateFormData } from '@/redux/thunk/supplierThunk';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const ChangeStatus = async (
    updatedStatusData: SupplierStatusUpdateFormData,
) => {
    const method: Method = API_METHOD_PUT;
    const url = API_SUPPLIER + '/' + updatedStatusData.supplierUuid + '/status';
    const data = {
        is_active: updatedStatusData.is_active,
    };

    const jsonString = JSON.stringify(data);
    const additionalHeaders = { hash: generateHash(jsonString) };

    try {
        const response = await apiCall({
            method,
            url,
            data,
            headers: additionalHeaders,
        });
        return response;
    } catch (error) {
        throw error;
    }
};
