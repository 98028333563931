// api/register.ts
import { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_POST, API_TIMESHEETS } from '@/constants/apiConstants';

export const AddTimesheet = async (payload: any) => {
  const method: Method = API_METHOD_POST;
  const url = API_TIMESHEETS;
  const data = payload;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
