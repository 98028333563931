import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_GET_TEAM_MEMBER_BY_ID,
  API_METHOD_GET,
} from '@/constants/apiConstants';

export const GetTeamMemberById = async (uuid: string) => {
  const method: Method = API_METHOD_GET;
  const url = API_GET_TEAM_MEMBER_BY_ID + `/${uuid}`;
  const data = '';

  try {
    return await apiCall({
      method,
      url,
      data,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
