'use client';

import React, { useState } from 'react';
import { useLockedBody } from '../hooks/useBodyLock';
import { NavbarWrapper } from '../navbar/navbar';
// import { SidebarWrapper } from '../sidebar/sidebar';
import { SidebarContext } from './layout-context';
import { BottomNav } from '../bottomnav/index';
import { BottomNavMenu } from '../bottomnav/bottomnav-menu';
import BreadcrumbComponent from '@/components/breadcrumbs';
import { useSelector } from 'react-redux';
import { RootState } from '@/redux/store';
import { QuoteContext } from './quote-context';

interface Props {
  children: React.ReactNode;
}

export const Layout = ({ children }: Props) => {
  const [sidebarOpen, setSidebarOpen] = React.useState(false);
  const [selectedUuid, setSelectedUuid] = useState<string | null>(null);
  const [_, setLocked] = useLockedBody(false);
  const handleToggleSidebar = () => {
    setSidebarOpen(!sidebarOpen);
    setLocked(!sidebarOpen);
  };

  const breadcrumbs = useSelector(
    (state: RootState) => state.breadcrumb.breadcrumbs,
  );

  return (
    <SidebarContext.Provider
      value={{
        collapsed: sidebarOpen,
        setCollapsed: handleToggleSidebar,
      }}
    >
      <QuoteContext.Provider value={{ selectedUuid, setSelectedUuid }}>
        <section className="flex bg-light-primary-100 mb-[70px] h-[92vh] overflow-hidden z-10 shadow-sm">
          <NavbarWrapper>
            {/* <div className="pb-4 bg-light-primary-100"> */}
            <div className="fixed pb-4 bg-light-primary-100 z-10 w-full">
              <BreadcrumbComponent breadcrumbPath={breadcrumbs} />
            </div>

            {/* Scrollable Content */}
            <div
              id="page-data"
              className="flex-1 mt-[45px] h-[calc(100vh-232px)] overflow-y-auto scrollbar-hide bg-light-layout-background rounded-2xl"
            >
              {/* <div id="page-data" className="h-[max-content] min-h-[calc(100vh-205px)] w-auto overflow-scroll scrollbar-hide rounded-xl bg-light-layout-background"> */}
              <div className="px-5 py-10">{children}</div>
            </div>
          </NavbarWrapper>
        </section>
        <BottomNav>
          <BottomNavMenu />
        </BottomNav>
      </QuoteContext.Provider>
    </SidebarContext.Provider>
  );
};
