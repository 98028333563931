// api/register.ts
import { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { Customer } from '@/helpers/types';
import { API_CUSTOMERS, API_METHOD_POST } from '@/constants/apiConstants';

export const AddCustomerData = async (values: Customer) => {
  const method: Method = API_METHOD_POST;
  const url = API_CUSTOMERS;
  const data = {
    title: values?.title,
    name: values?.name,
    company_name: values?.company_name,
    phone: values?.phone,
    email: values?.email,
    referred_by: values?.referred_by,
    site_name: values?.sites[0].name,
    site_details: values?.sites[0].details,
    site_street_number: values.sites[0].address.street_number,
    site_street_name: values.sites[0].address.street_name,
    site_suburb: values.sites[0].address.suburb,
    site_city: values.sites[0].address.city,
    site_postal_code: values.sites[0].address.postal_code,
    site_region: values.sites[0].address.region,
    site_country: values.sites[0].address.country,
    site_lat: values.sites[0].address.latitude,
    site_lng: values.sites[0].address.longitude,
    site_search_keyword: values.sites[0].address.search_keyword,
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
