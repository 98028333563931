/* eslint-disable prettier/prettier */
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetCustomerPortalProfileData } from '@/api/customerPortal/getCustomers';
import { CustomerPortalProfileType } from '@/helpers/types/customerPortalType';

// Define the type for the thunk return value
type fetchCustomerPortalProfileResponse = {
    data: CustomerPortalProfileType;
};

type fetchCustomerPortalProfileArgs = {
    uniqueKey: string;
}

type FetchCustomersError = string | SerializedError;

export const fetchCustomerPortalProfile = createAsyncThunk<
    fetchCustomerPortalProfileResponse, // Return type of the payload creator
    fetchCustomerPortalProfileArgs, // Argument type of the payload creator
    {
        rejectValue: FetchCustomersError; // Type for rejected value
    }
>('CustomerPortalProfile/fetchCustomerPortalProfile', async ({ uniqueKey }, { rejectWithValue }) => {
    try {
        const response = await GetCustomerPortalProfileData(uniqueKey);
        const data: fetchCustomerPortalProfileResponse = response.data;
        return { data: data.data }; // Return formatted data
    } catch (error) {
        // Type guard to narrow down error to Error type
        if (error instanceof Error) {
            return rejectWithValue(error.message);
        }
        // Fallback for unknown error types
        return rejectWithValue('An unknown error occurred');
    }
});
