'use client';

import React, { useEffect } from 'react';
import { BreadcrumbItem, Breadcrumbs, Button } from '@nextui-org/react';
import NextLink from 'next/link';
import { ExportIcon } from '@/components/icons/exports';
import { ChartIcon } from '@/components/icons/bottomnav/chart-icon';
import { usePathname } from 'next/navigation';
import { useDispatch } from 'react-redux';
import { AppDispatch } from '@/redux/store';

interface breadCrumbItem {
  name: string;
  path: string | any;
}

interface breadcrumbItemsArray {
  breadcrumbPath?: breadCrumbItem[];
}

const BreadcrumbComponent: React.FC<breadcrumbItemsArray> = ({
  breadcrumbPath,
}) => {
  const pathname = usePathname();
  const dispatch = useDispatch<AppDispatch>();
  const [currentPage, setCurrentPage] = React.useState<string>('');

  useEffect(() => {
    console.log('pathname', pathname);
    setCurrentPage(pathname);
  }, [dispatch, pathname]);
  return (
    <div className="hidden lg:block text-start mt-2 pl-[40px] pr-[106px]">
      <div className="flex justify-between items-center">
        <Breadcrumbs separator="/" itemClasses={{ separator: 'px-2' }}>
          {breadcrumbPath?.map((item, index) => {
            return (
              <BreadcrumbItem key={index}>
                <NextLink
                  href={item.path || ''}
                  className={`font-normal text-xl ${index == 0 && breadcrumbPath?.length != 1 ? 'text-black' : ''}`}
                >
                  {item.name}
                </NextLink>
              </BreadcrumbItem>
            );
          })}
        </Breadcrumbs>
        <div className={`${currentPage === '/report' ? '' : 'hidden'} -mt-3`}>
          <div className="flex h-10 items-center bg-white border-2 border-solid border-light-base-primary rounded-md w-fit">
            <Button
              size="md"
              radius="sm"
              className={`flex items-center justify-center w-28 lg:w-[141px] rounded-l-md rounded-r-none transition-all bg-light-base-primary text-white`}
            >
              <ExportIcon color={'white'} />
              Exports
            </Button>
            <Button
              size="md"
              radius="sm"
              disabled={true}
              className={`flex items-center justify-center w-28 lg:w-[141px] rounded-r-md rounded-l-none transition-all bg-transparent text-light-base-primary`}
            >
              <ChartIcon color={'#34C100'} height="24" width="24" />
              Reports
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default BreadcrumbComponent;
