import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_EMAIL_TEMPLATE, API_METHOD_GET } from '@/constants/apiConstants';

export const GetEmailTemplateById = async (templateCode: string) => {
  const method: Method = API_METHOD_GET;
  const url = API_EMAIL_TEMPLATE + `/${templateCode}`;
  const data = '';

  try {
    return await apiCall({
      method,
      url,
      data,
    }); // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
