import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobByUuid } from '../thunk/jobThunk';
import {
  PurchaseOrderProduct,
  SinglePurchaseOrderByUuid,
} from '@/helpers/types/puchaseOrderTypes';
import { fetchPurchaseOrderByUuid } from '@/redux/thunk/purchaseOderThunk';

interface PurchaseOrderState {
  singlePurchaseOrder: SinglePurchaseOrderByUuid;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: PurchaseOrderState = {
  singlePurchaseOrder: {
    uuid: '',
    purchase_order_number: '',
    supplier: {
      id: '',
      name: '',
    },
    order_date: '',
    delivery_date: '',
    inventory: {
      id: '',
      name: '',
    },
    job: {
      id: '',
      title: '',
      job_number: '',
    },
    status: 0, // Default to a valid `number` value
    reference: '',
    description: '',
    subtotal: 0, // Default to `number`
    tax_amount: 0, // Default to `number`
    total: 0, // Default to `number`
    products: [
      {
        uuid: '',
        product: {
          uuid: '',
          company: {
            id: '',
            name: '',
          },
          item_type: 0,
          item_type_label: '',
          name: '',
          description: '',
          cost: 0,
          unit_price: 0,
          image_url: {
            link: '',
            size: '',
            name: '',
          },
          is_tax_exempted: 0,
          is_tax_exempted_label: '',
        },
        unit_price: 0, // Default to `number`
        quantity: 0, // Default to `number`
        total: 0, // Default to `number`
      },
    ],
    notes: [
      {
        uuid: '',
        note: '',
        attachment: {
          link: '',
          size: '',
          name: '',
        },
        created_at: '',
      },
    ],
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const singlePurchaseOrderSlice = createSlice({
  name: 'singlePurchaseOrder',
  initialState,
  reducers: {
    clearSinglePurchaseOrder(state) {
      state.singlePurchaseOrder = initialState.singlePurchaseOrder;
    },
  },
  extraReducers: (builder) => {
    // Handle fetchPurchaseOrder (existing functionality)
    builder
      .addCase(fetchPurchaseOrderByUuid.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchPurchaseOrderByUuid.fulfilled,
        (state, action: PayloadAction<{ data: SinglePurchaseOrderByUuid }>) => {
          state.singlePurchaseOrder = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchPurchaseOrderByUuid.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSinglePurchaseOrder } = singlePurchaseOrderSlice.actions;
export default singlePurchaseOrderSlice.reducer;
