import { SupplierNoteType } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchSupplierAllNotes } from '../thunk/supplierNoteThunk';

interface SupplierAllNoteState {
  supplierNotes: SupplierNoteType[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: SupplierAllNoteState = {
  supplierNotes: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const supplierAllNoteSlice = createSlice({
  name: 'supplier-all-note',
  initialState,
  reducers: {
    clearSupplierNotes(state) {
      state.supplierNotes = initialState.supplierNotes;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchSupplierAllNotes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchSupplierAllNotes.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: SupplierNoteType[];
          }>,
        ) => {
          state.status = 'succeeded';
          state.supplierNotes = action.payload.data;
        },
      )
      .addCase(fetchSupplierAllNotes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearSupplierNotes } = supplierAllNoteSlice.actions;
export default supplierAllNoteSlice.reducer;
