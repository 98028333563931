import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_METHOD_PUT, API_UPDATE_QUOTE } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const UpdateQuote = async (payload: any) => {
  const method: Method = API_METHOD_PUT;
  const url = `${API_UPDATE_QUOTE}/${payload?.uuid}`;
  const data = payload;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
