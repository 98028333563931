import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchPermission } from '@/redux/thunk/permissionThunk';

interface PermissionList {
  permissionList: [];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: PermissionList = {
  permissionList: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const permissionListSlice = createSlice({
  name: 'permissionList',
  initialState,
  reducers: {
    clearPermissionList(state) {
      state.permissionList = initialState.permissionList;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchPermission.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchPermission.fulfilled,
        (state, action: PayloadAction<{ data: [] }>) => {
          state.permissionList = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchPermission.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearPermissionList } = permissionListSlice.actions;
export default permissionListSlice.reducer;
