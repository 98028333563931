import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { PurchaseOrderStatusData } from '@/helpers/types/puchaseOrderTypes';
import { GetPurchaseOrderStatus } from '@/api/purchaseOrder/getPurchaseOrderStatus';

type FetchPurchaseOrderStatusResponse = {
  data: PurchaseOrderStatusData[];
};

type FetchPurchaseOrderStatusError = string | SerializedError;

// Fetch purchase order status
export const fetchPurchaseOrderStatus = createAsyncThunk<
  FetchPurchaseOrderStatusResponse,
  void,
  { rejectValue: FetchPurchaseOrderStatusError }
>(
  'purchaseOrderStatus/fetchPurchaseOrderStatus',
  async (_, { rejectWithValue }) => {
    try {
      const response = await GetPurchaseOrderStatus();
      return response;
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);
