import { Chip } from '@nextui-org/react';
import clsx from 'clsx';
import Link from 'next/link';
import { usePathname } from 'next/navigation';
import React, { ReactElement, useState } from 'react';

interface Props {
  icon: ReactElement;
  title: string;
  path: string;
  href: string;
}

export const BottomNavItem: React.FC<Props> = ({ icon, title, path, href }) => {
  const currentPath = usePathname();
  const [isHover, setIsHover] = useState(false);

  const isActive =
    path === currentPath || currentPath.startsWith(path + '/') || isHover;

  return (
    <div className="relative">
      {isHover && (
        <Chip className="absolute left-1/2 -top-5 transform -translate-x-1/2 -translate-y-1/2 text-light-base-primary bg-light-layout-background">
          {title}
        </Chip>
      )}
      <Link href={href} passHref>
        <div
          className={clsx(
            'p-2 lg:p-2 xl:p-2 border-1 rounded-2xl transition-colors ease-out duration-300',
            {
              'bg-light-base-primary border-light-base-primary': isActive,
              'border-[#E0E0E0] bg-light-layout-background': !isActive,
            },
          )}
          onMouseEnter={() => setIsHover(true)}
          onMouseLeave={() => setIsHover(false)}
        >
          {React.cloneElement(icon, {
            color: isActive ? '#FFFFFF' : '#797A7C',
          })}
        </div>
      </Link>
    </div>
  );
};
