// api/register.ts
import { Method } from 'axios';
import { generateHash } from '@/utility/auth/generateHashValue';
import { apiCall } from '@/utility/api/apiUtils';
import { API_CUSTOMERS, API_METHOD_POST } from '@/constants/apiConstants';

export const ImportCustomer = async (payload: {
  file_id: string | null;
  import_type: string;
}) => {
  const method: Method = API_METHOD_POST;
  const url = API_CUSTOMERS + `/import`;

  const data = {
    file_id: payload.file_id, // Use file_id correctly
    import_type: payload.import_type,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString), // Assuming this is correctly implemented elsewhere
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return response for further handling
  } catch (error) {
    console.error('Error in ImportCustomer API call:', error);
    throw error; // Handle the error in caller function
  }
};
