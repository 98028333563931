import { JobTypeData } from '@/helpers/types';
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchJobTypes } from '@/redux/thunk/jobTypeThunk';

interface JobTypeState {
  jobType: JobTypeData[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: JobTypeState = {
  jobType: [],
  status: 'idle',
  error: null,
};

const jobTypeSlice = createSlice({
  name: 'job-type',
  initialState,
  reducers: {
    clearJobType(state) {
      state.jobType = initialState.jobType;
    },
  },
  extraReducers: (builder) => {
    // Fetch enquiry status
    builder
      .addCase(fetchJobTypes.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchJobTypes.fulfilled,
        (state, action: PayloadAction<{ data: JobTypeData[] }>) => {
          state.status = 'succeeded';
          state.jobType = action?.payload?.data;
        },
      )
      .addCase(fetchJobTypes.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearJobType } = jobTypeSlice.actions;
export default jobTypeSlice.reducer;
