import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTimesheets } from '@/redux/thunk/timesheetThunk';
import { fetchEmailTemplateSettings } from '@/redux/thunk/emailTemplateThunk';

interface EmailTemplateSettings {
  email_sender: string;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: EmailTemplateSettings = {
  email_sender: '',
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const emailTemplateSettingsSlice = createSlice({
  name: 'emailTemplateSettings',
  initialState,
  reducers: {
    clearEmailTemplateSettings(state) {
      state.email_sender = initialState.email_sender;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailTemplateSettings.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEmailTemplateSettings.fulfilled,
        (state, action: PayloadAction<{ email_sender: string }>) => {
          state.email_sender = action.payload.email_sender;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchTimesheets.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEmailTemplateSettings } =
  emailTemplateSettingsSlice.actions;
export default emailTemplateSettingsSlice.reducer;
