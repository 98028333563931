// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_METHOD_GET,
  API_FETCH_MAIN_INVENTORIES,
} from '@/constants/apiConstants';

export const SearchMainInventries = async (
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
) => {
  const method: Method = API_METHOD_GET;
  const url =
    API_FETCH_MAIN_INVENTORIES +
    `?search=${searchTerm}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
