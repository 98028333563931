import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_EMAIL_TEMPLATE, API_METHOD_PUT } from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';

export const UpdateEmailTemplate = async (
  templateCode: string,
  payload: {
    body: string;
  },
) => {
  const method: Method = API_METHOD_PUT;
  const url = `${API_EMAIL_TEMPLATE}/${templateCode}`;
  const data = payload;

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    return await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    }); // Return the response data for further use
  } catch (error) {
    throw error;
  }
};
