import { API_METHOD_GET, API_PRODUCTS } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const SearchProduct = async (
  filteredType: number | undefined,
  searchTerm: string,
  productCountPerPage: number,
  pageNumber: number,
) => {
  const method: Method = API_METHOD_GET;
  const searchTermEncoded = encodeURIComponent(searchTerm);
  const type =
    filteredType !== undefined ? encodeURIComponent(filteredType) : '';
  const count =
    productCountPerPage !== undefined ? encodeURIComponent(productCountPerPage) : '';
  const number = pageNumber !== undefined ? encodeURIComponent(pageNumber) : '';
  const url = `${API_PRODUCTS}?search=${searchTermEncoded}${type ? `&f-item_type=${type}` : ''}${count ? `&p-size=${count}` : ''}${number ? `&p=${number}` : ''}`;

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
