// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_FETCH_ALL_INVENTORY,
  API_FETCH_INVENTORY_ITEMS,
  API_METHOD_GET,
  API_METHOD_POST,
  API_TRANSFER_INVENTORIES,
} from '@/constants/apiConstants';
import { generateHash } from '@/utility/auth/generateHashValue';
import { TransferInventoryProducts } from '@/helpers/types/inventoryTypes';

export const TransferInventries = async (
  from_inventory_id: string,
  to_inventory_id: string,
  reason: number,
  status: number,
  products: TransferInventoryProducts[],
) => {
  const method: Method = API_METHOD_POST;
  const url = API_TRANSFER_INVENTORIES;

  const data = {
    from_inventory_id: from_inventory_id,
    to_inventory_id: to_inventory_id,
    reason: reason,
    status: status,
    products: products,
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
