import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CustomerSite } from '@/helpers/types/customerSiteTypes';
import { fetchCustomerSites } from '../thunk/customerSiteThunk';
import { Pagination } from '@/helpers/types';

interface CustomerSiteState {
  sites: CustomerSite[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
}

const initialState: CustomerSiteState = {
  sites: [],
  status: 'idle',
  error: null,
};

const customerSiteSlice = createSlice({
  name: 'customerSites',
  initialState,
  reducers: {
    clearSites(state) {
      state.sites = [];
    },
  },
  extraReducers: (builder) => {
    builder
      // Handle fetchCustomerSites
      .addCase(fetchCustomerSites.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchCustomerSites.fulfilled,
        (
          state,
          action: PayloadAction<{
            data: CustomerSite[];
            pagination: Pagination;
          }>,
        ) => {
          state.sites = action.payload; // Set the fetched sites
          state.status = 'succeeded';
        },
      )
      .addCase(fetchCustomerSites.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string; // Adjust this if your payload type is different
      });
  },
});

export const { clearSites } = customerSiteSlice.actions;
export default customerSiteSlice.reducer;
