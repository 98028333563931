import { API_JOBS, API_METHOD_DELETE } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const DeleteJobPhotoData = async (
  jobUuid: string,
  jobPhotoUuId: string,
) => {
  const method: Method = API_METHOD_DELETE;
  const url = API_JOBS + `/${jobUuid}/photos/bulk`;
  const data = {
    ids: [jobPhotoUuId],
  };

  // Convert JSON object to string
  const jsonString = JSON.stringify(data);
  const additionalHeaders = {
    hash: generateHash(jsonString),
  };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
