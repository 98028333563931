import { JobTypeData } from '@/helpers/types';
import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { GetJobType } from '@/api/job/getJobType';

type FetchJobTypeResponse = {
  data: JobTypeData[];
};

type FetchJobTypeError = string | SerializedError;

// Fetch job type
export const fetchJobTypes = createAsyncThunk<
  FetchJobTypeResponse,
  void,
  { rejectValue: FetchJobTypeError }
>('jobTypes/fetchJobType', async (_, { rejectWithValue }) => {
  try {
    const response = await GetJobType();
    const data: FetchJobTypeResponse = response;
    return data;
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});
