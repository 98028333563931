import { ChartIcon } from '../icons/bottomnav/chart-icon';
import { CustomerIcon } from '../icons/bottomnav/customer-icon';
import { EnquiryIcon } from '../icons/bottomnav/enquiry-icon';
import { ExpenseIcon } from '../icons/bottomnav/expense-icon';
import { HomeIcon } from '../icons/bottomnav/home-icon';
import { InventoryIcon } from '../icons/bottomnav/inventory-icon';
import { InvoiceIcon } from '../icons/bottomnav/invoice-icon';
import { JobIcon } from '../icons/bottomnav/job-icon';
import { ProductIcon } from '../icons/bottomnav/product-icon';
import { PurchaseIcon } from '../icons/bottomnav/purchase-icon';
import { QuoteIcon } from '../icons/bottomnav/quote-icon';
import { SupplierIcon } from '../icons/bottomnav/supplier-icon';
import { TeamIcon } from '../icons/bottomnav/team-icon';
import { TimerIcon } from '../icons/bottomnav/timer-icon';

export const NAV_ITEMS = [
  {
    icon: <HomeIcon color="red" />,
    title: 'Home',
    path: '/',
    href: '/',
  },
  {
    icon: <EnquiryIcon color="red" />,
    title: 'Requests',
    path: '/enquiry',
    href: '/enquiry',
  },
  {
    icon: <CustomerIcon color="red" />,
    title: 'Clients',
    path: '/customers',
    href: '/customers',
  },
  {
    icon: <QuoteIcon color="red" />,
    title: 'Quotes',
    path: '/quote',
    href: '/quote',
  },
  {
    icon: <SupplierIcon color="red" />,
    title: 'Suppliers',
    path: '/supplier',
    href: '/supplier',
  },
  {
    icon: <ProductIcon color="red" />,
    title: 'Products & Services',
    path: '/product',
    href: '/product',
  },

  {
    icon: <InventoryIcon color="red" />,
    title: 'Inventories',
    path: '/inventory',
    href: '/inventory',
  },
  {
    icon: <JobIcon color="red" />,
    title: 'Jobs',
    path: '/job',
    href: '/job',
  },
  {
    icon: <PurchaseIcon color="red" />,
    title: 'Purchases',
    path: '/purchase-order',
    href: '/purchase-order',
  },
  {
    icon: <TeamIcon color="red" />,
    title: 'Teams',
    path: '/team',
    href: '/team',
  },
  {
    icon: <TimerIcon color="red" />,
    title: 'Timesheets',
    path: '/timesheet',
    href: '/timesheet',
  },
  {
    icon: <ExpenseIcon color="red" />,
    title: 'Expenses',
    path: '/expenses',
    href: '/expenses',
  },
  {
    icon: <InvoiceIcon color="red" />,
    title: 'Invoices',
    path: '/invoice',
    href: '/invoice',
  },
  {
    icon: <ChartIcon color="red" />,
    title: 'Reports',
    path: '/report',
    href: '/report',
  },
];
