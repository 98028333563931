import { API_METHOD_POST, API_SUPPLIER } from '@/constants/apiConstants';
import { SupplierFormData } from '@/helpers/types';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import { Method } from 'axios';

export const AddSupplier = async (newSupplierData: SupplierFormData) => {
  const method: Method = API_METHOD_POST;
  const url = API_SUPPLIER;
  const data = {
    name: newSupplierData.name,
    contact_name: newSupplierData.contact_name,
    phone: newSupplierData.phone,
    email: newSupplierData.email,
    type: newSupplierData.type,
    address: {
      street_number: newSupplierData.address.street_number,
      street_name: newSupplierData.address.street_name,
      suburb: newSupplierData.address.suburb,
      city: newSupplierData.address.city,
      postal_code: newSupplierData.address.postal_code,
      region: newSupplierData.address.region,
      country: newSupplierData.address.country,
      latitude: newSupplierData.address.latitude,
      longitude: newSupplierData.address.longitude,
      search_keyword: newSupplierData.address.search_keyword,
    },
    notes: newSupplierData.notes,
  };

  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const response = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });
    return response;
  } catch (error) {
    throw error;
  }
};
