// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_JOBS, API_METHOD_POST } from '@/constants/apiConstants';
import { toast } from 'react-toastify';

export const JobSendEmail = async (uuid: string) => {
  const method: Method = API_METHOD_POST;
  const url = API_JOBS + `/${uuid}` + `/email`;

  try {
    const response = await apiCall({
      method,
      url,
    });
    toast.success(response?.message);
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
