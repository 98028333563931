import { API_METHOD_GET, API_SUPPLIER } from '@/constants/apiConstants';
import { apiCall } from '@/utility/api/apiUtils';
import { Method } from 'axios';

export const SearchSupplier = async (
  searchTerm: string,
  filteredType: number | undefined,
  supplierCountPerPage: number,
  pageNumber: number,
  filterDateRange: {
    start: string;
    end: string;
  },
) => {
  const method: Method = API_METHOD_GET;

  let url = `${API_SUPPLIER}?search=${encodeURIComponent(searchTerm)}`;

  url += `&f-status=${filteredType !== undefined ? encodeURIComponent(filteredType) : ''}`;
  url += `&p-size=${supplierCountPerPage !== undefined ? encodeURIComponent(supplierCountPerPage) : ''}`;
  url += `&p=${pageNumber !== undefined ? encodeURIComponent(pageNumber) : ''}`;
  if (filterDateRange.start && filterDateRange.end) {
    url += `&f-requested_on=${encodeURIComponent(`${filterDateRange.start}:${filterDateRange.end}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response;
  } catch (error) {
    throw error;
  }
};

// quotes/?search=&sort-by=&sort-order=desc&p=1&p-size=10&f-status=0&f-created_at=2024-01-05:2024-12-06
