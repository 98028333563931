// api/customers.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import {
  API_COMMON_TEAMMEMBER_STATUS,
  API_METHOD_GET,
} from '@/constants/apiConstants';

export const GetTeamMemberStatusData = async () => {
  const method: Method = API_METHOD_GET;
  const url = API_COMMON_TEAMMEMBER_STATUS;
  const data = '';

  try {
    const response = await apiCall({
      method,
      url,
      data,
    });

    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
