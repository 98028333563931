// api/register.ts
import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { API_EXPENSES, API_METHOD_GET } from '@/constants/apiConstants';

export const SearchExpensesData = async (
  searchTerm: string,
  pageSize?: string,
  pagination?: number,
  startDate?: string | null,
  endDate?: string | null,
) => {
  const method: Method = API_METHOD_GET;
  let url =
    API_EXPENSES +
    `?search=${searchTerm}` +
    (pageSize && `&p-size=${pageSize}`) +
    (pagination && `&p=${pagination}`);

  if (startDate && endDate) {
    url += `&f-date=${encodeURIComponent(`${startDate}:${endDate}`)}`;
  }

  try {
    const response = await apiCall({
      method,
      url,
    });
    return response; // Return the response data for further use
  } catch (error) {
    throw error; // Re-throw the error to be handled by the caller
  }
};
