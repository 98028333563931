import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchEmailTemplateSenderList } from '@/redux/thunk/emailTemplateThunk';
import { EmailSenderList } from '@/helpers/types';

interface EmailTemplateSenderList {
  data: EmailSenderList[];
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>; // Optional: Store field-specific errors
}

const initialState: EmailTemplateSenderList = {
  data: [],
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const emailTemplateSenderListSlice = createSlice({
  name: 'emailTemplateSenderList',
  initialState,
  reducers: {
    clearEmailTemplateSenderList(state) {
      state.data = initialState.data;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchEmailTemplateSenderList.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchEmailTemplateSenderList.fulfilled,
        (state, action: PayloadAction<{ data: EmailSenderList[] }>) => {
          state.data = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchEmailTemplateSenderList.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearEmailTemplateSenderList } =
  emailTemplateSenderListSlice.actions;
export default emailTemplateSenderListSlice.reducer;
