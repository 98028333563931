// teamSlice.ts
import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ExpensesMetricsData } from '@/helpers/types';
import { fetchExpensesMetrics } from '@/redux/thunk/expensesThunk';

interface ExpensesMetricsState {
  expensesMetrics: ExpensesMetricsData;
  status: 'idle' | 'loading' | 'succeeded' | 'failed';
  error: string | null;
  fieldErrors?: Record<string, string[]>;
}

const initialState: ExpensesMetricsState = {
  expensesMetrics: {
    total_expense: '',
  },
  status: 'idle',
  error: null,
  fieldErrors: {},
};

const expensesMetricsSlice = createSlice({
  name: 'expense-metrics',
  initialState,
  reducers: {
    clearExpensesMetrics(state) {
      state.expensesMetrics = initialState.expensesMetrics;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchExpensesMetrics.pending, (state) => {
        state.status = 'loading';
        state.error = null;
      })
      .addCase(
        fetchExpensesMetrics.fulfilled,
        (state, action: PayloadAction<{ data: ExpensesMetricsData }>) => {
          state.expensesMetrics = action.payload.data;
          state.status = 'succeeded';
        },
      )
      .addCase(fetchExpensesMetrics.rejected, (state, action) => {
        state.status = 'failed';
        state.error = action.payload as string;
      });
  },
});

export const { clearExpensesMetrics } = expensesMetricsSlice.actions;
export default expensesMetricsSlice.reducer;
