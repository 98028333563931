import React from 'react';

export const MoreNewArrow = ({ color = '#6D788D' }) => {
  return (
    <svg
      width="12"
      height="12"
      viewBox="0 0 9 17"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M1 1.5L8 8.5L1 15.5"
        stroke={color}
        strokeWidth="2"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
    </svg>
  );
};
