// src/api/login.ts

import { Method } from 'axios';
import { apiCall } from '@/utility/api/apiUtils';
import { generateHash } from '@/utility/auth/generateHashValue';
import encryptStringWithRSA from '@/utility/auth/encryptStringWithRSA';
import NodeRSA from 'node-rsa';
import { toast } from 'react-toastify';
import {
  API_AUTH_LOGIN_URL,
  API_COMMON_PUBLIC_KEY_URL,
  API_METHOD_POST,
} from '@/constants/apiConstants';
import { Firebase } from '@/notifications/firebase';

export const getPublicKeyFromServer = async (): Promise<string | null> => {
  const method: Method = API_METHOD_POST;
  const url = API_COMMON_PUBLIC_KEY_URL;
  const data = '';
  const additionalHeaders = { hash: '' };

  try {
    const responseData = await apiCall({
      method,
      url,
      data,
      headers: additionalHeaders,
    });

    const publicKey = responseData?.data?.public_key;
    return publicKey;
  } catch (error) {
    toast.error('An unexpected error occurred. Please try again.');
    return null;
  }
};

export const loginUser = async (values: {
  email: string;
  password: string;
}) => {
  const publicKey = await getPublicKeyFromServer();

  if (!publicKey) {
    toast.error('An unexpected error occurred. Please try again.');
    return;
  }

  const decodedPublicKey = Buffer.from(publicKey, 'base64').toString('utf8');
  const key = new NodeRSA(decodedPublicKey);
  key.setOptions({ encryptionScheme: 'pkcs1' });

  const encryptedPassword = encryptStringWithRSA(key, values.password);
  const retrieveToken = async () => {
    const token = await Firebase();
    if (token) {
      return token;
    } else {
      return '';
    }
  };
  const firebaseToken = await retrieveToken();
  const data = {
    email: values.email,
    password: encryptedPassword,
    firebase_token: firebaseToken,
    encryption_keys: 'password',
  };
  const jsonString = JSON.stringify(data);
  const additionalHeaders = { hash: generateHash(jsonString) };

  try {
    const responseData = await apiCall({
      method: API_METHOD_POST,
      url: API_AUTH_LOGIN_URL,
      data,
      headers: additionalHeaders,
    });

    toast.success(responseData?.message);

    localStorage.setItem(
      'REFRESH_TOKEN',
      responseData?.data?.refresh_token?.token,
    );
    localStorage.setItem(
      'ACCESS_TOKEN',
      responseData?.data?.access_token?.token,
    );

    return responseData;
  } catch (error) {
    throw error;
  }
};
