import { createAsyncThunk, SerializedError } from '@reduxjs/toolkit';
import { Pagination } from '@/helpers/types';
import { GetQuotesData } from '@/api/quote/getQuotes';
import { SearchQuotesData } from '@/api/quote/searchQuotes';
import { DeleteQuoteData } from '@/api/quote/deleteQuote';
import {
  Note,
  Quote,
  QuoteDuplicateData,
  QuoteFormData,
  SingleQuoteByUuid,
} from '@/helpers/types/quoteTypes';
import { FetchQuoteByUuid } from '@/api/quote/fetchQuoteByUuid';
import { CreateQuote } from '@/api/quote/createQuote';
import { toast } from 'react-toastify';
import { GetQuoteNotesData } from '@/api/quote/getQuoteNotes';
import { AddQuoteNoteData } from '@/api/quote/addQuoteNote';
import { GetQuoteDownloadData } from '@/api/quote/getQuoteDownload';
import { QuoteSendEmail } from '@/api/quote/quoteSendEmail';
import { UpdateQuoteNoteData } from '@/api/quote/updateQuoteNote';
import { DuplicateQuote } from '@/api/quote/duplicateQuote';
import { UpdateQuote } from '@/api/quote/updateQuote';
import { UpdateQuoteStatus } from "@/api/quote/updateQuoteStatus";

// Define the type for the API response
interface QuoteResponse {
  data: Quote[];
  pagination: Pagination;
}

// Define the type for the thunk's return value
type FetchQuotesResponse = {
  data: Quote[];
  pagination: Pagination;
};

type AddQuoteNoteResponse = {
  data: {
    uuid: string;
    note: string;
    attachment: null | string;
  };
  message: string;
};

type FetchQuoteNoteResponse = {
  data: Note[];
};

type FetchQuoteDownloadLinkResponse = {
  data: string;
};

type QuoteSendEmailResponse = {
  // data
  message: string;
};

type FetchQuoteByUuidResponse = {
  data: SingleQuoteByUuid;
};

type SearchQuotesArgs = {
  searchTerm: string;
  selectedStatus: number;
  pageSize: string;
  pagination: number;
  startDate: string;
  endDate: string;
  selectedCustomer?: string;
};

interface CreateQuoteResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

interface UpdateQuoteResponse {
  message(message: any): unknown;

  data: any; // Adjust based on your API's response structure
}

type updateQuoteNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
  quoteUuid: string;
};

type FetchQuoteByUuidArgs = string;
type FetchQuoteNotesByUuidArgs = string;
type FetchQuoteDownloadLinkArgs = string;
type AddQuoteNoteArgs = {
  uuid: string;
  note: string;
  attachment: string;
};
interface UpdateQuoteStatusArgs  {
  selectedQuote: string;
  selectedStatus: string;
}
type FetchQuotesError = string | SerializedError;
type CreateQuotesError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};
type UpdateQuotesError = {
  message: string;
  messages?: any; // Add any or a specific type for messages if needed
};
type DeleteQuotesArgs = string;
type QuoteSendEmailArgs = string;

interface CreateQuoteArgs extends QuoteFormData {}

interface UpdateQuoteArgs extends QuoteFormData {}

type DuplicateQuoteArgs = QuoteDuplicateData;

export const fetchQuotes = createAsyncThunk<
  FetchQuotesResponse, // Return type of the payload creator
  void, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('quote/fetchQuotes', async (_, { rejectWithValue }) => {
  try {
    const response = await GetQuotesData();
    const data: QuoteResponse = response?.data;
    return { data: data.data, pagination: data.pagination }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const searchQuotes = createAsyncThunk<
  FetchQuotesResponse,
  SearchQuotesArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'quote/searchQuotes',
  async (
    {
      searchTerm,
      selectedStatus,
      pageSize,
      pagination,
      startDate,
      endDate,
      selectedCustomer,
    },
    { rejectWithValue },
  ) => {
    try {
      const response = await SearchQuotesData(
        searchTerm,
        selectedStatus,
        pageSize,
        pagination,
        startDate,
        endDate,
        selectedCustomer,
      );
      const data: QuoteResponse = response.data;
      return { data: data.data, pagination: data.pagination };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const deleteQuote = createAsyncThunk<
  void, // Return type of the payload creator
  DeleteQuotesArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>(
  'quote/deleteQuote',
  async (teamMemberUuId: string, { dispatch, rejectWithValue }) => {
    try {
      // Call the API to delete the quote
      await DeleteQuoteData(teamMemberUuId);

      // After successful deletion, dispatch fetchquote to refresh the list
      dispatch(fetchQuotes());
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue(error.message);
      }
      return rejectWithValue('An unknown error occurred');
    }
  },
);

export const fetchQuoteByUuid = createAsyncThunk<
  FetchQuoteByUuidResponse,
  FetchQuoteByUuidArgs,
  {
    rejectValue: FetchQuotesError;
  }
>('quote/fetchQuoteByUuid', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await FetchQuoteByUuid(uuid);
    const data: SingleQuoteByUuid = response?.data;
    return { data: data };
  } catch (error) {
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    return rejectWithValue('An unknown error occurred');
  }
});

// Thunk for creating a quote
export const createQuote = createAsyncThunk<
  CreateQuoteResponse, // Success response type
  CreateQuoteArgs, // Request payload type
  { rejectValue: CreateQuotesError } // Error type for rejection
>(
  'quote/createQuote',
  async (quoteData: CreateQuoteArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await CreateQuote(quoteData);
      toast.success('Quote added successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchQuotes());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

// Thunk for update a quote
export const updateQuote = createAsyncThunk<
  UpdateQuoteResponse, // Success response type
  UpdateQuoteArgs, // Request payload type
  { rejectValue: UpdateQuotesError } // Error type for rejection
>(
  'quote/updateQuote',
  async (quoteData: UpdateQuoteArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await UpdateQuote(quoteData);
      toast.success('Quote updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchQuotes());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const fetchQuoteNotes = createAsyncThunk<
  FetchQuoteNoteResponse, // Return type of the payload creator
  FetchQuoteNotesByUuidArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('quote/fetchQuoteNotes', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await GetQuoteNotesData(uuid);
    const data: Note[] = response?.data;
    return { data: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const addQuoteNote = createAsyncThunk<
  AddQuoteNoteResponse,
  AddQuoteNoteArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'quote/addQuoteNote',
  async ({ uuid, note, attachment }, { dispatch, rejectWithValue }) => {
    try {
      const response = await AddQuoteNoteData(uuid, note, attachment);
      const data: AddQuoteNoteResponse = response;

      dispatch(fetchQuotes());
      // Ensure you return an object with a 'message' field
      return {
        data: data.data,
        message: data.message || 'Note added successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);

export const updateQuoteNote = createAsyncThunk<
  AddQuoteNoteResponse,
  updateQuoteNoteArgs,
  {
    rejectValue: FetchQuotesError;
  }
>(
  'quote/updateQuoteNote',
  async (
    { uuid, note, attachment, quoteUuid },
    { dispatch, rejectWithValue },
  ) => {
    try {
      const response = await UpdateQuoteNoteData(
        uuid,
        note,
        attachment,
        quoteUuid,
      );
      const data: AddQuoteNoteResponse = response;

      dispatch(fetchQuotes());
      // Ensure you return an object with a 'message' field
      return {
        data: data.data,
        message: data.message || 'Note added successfully', // Add the message field
      };
    } catch (error) {
      if (error instanceof Error) {
        return rejectWithValue({
          message: error.message,
        });
      }
      return rejectWithValue({
        message: 'An unknown error occurred',
      });
    }
  },
);

export const fetchQuoteDownloadLink = createAsyncThunk<
  FetchQuoteDownloadLinkResponse, // Return type of the payload creator
  FetchQuoteDownloadLinkArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('quote/fetchQuoteDownloadLink', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await GetQuoteDownloadData(uuid);
    const data: string = response?.data;
    return { data: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const quoteSendEmail = createAsyncThunk<
  QuoteSendEmailResponse, // Return type of the payload creator
  QuoteSendEmailArgs, // Argument type of the payload creator
  {
    rejectValue: FetchQuotesError; // Type for rejected value
  }
>('quote/quoteSendEmail', async (uuid: string, { rejectWithValue }) => {
  try {
    const response = await QuoteSendEmail(uuid);
    const data: string = response?.data;
    return { message: data }; // Return formatted data
  } catch (error) {
    // Type guard to narrow down error to Error type
    if (error instanceof Error) {
      return rejectWithValue(error.message);
    }
    // Fallback for unknown error types
    return rejectWithValue('An unknown error occurred');
  }
});

export const duplicateQuote = createAsyncThunk<
  CreateQuoteResponse, // Success response type
  DuplicateQuoteArgs, // Request payload type
  { rejectValue: CreateQuotesError } // Error type for rejection
>(
  'quote/createQuote',
  async (quoteData: DuplicateQuoteArgs, { dispatch, rejectWithValue }) => {
    try {
      // Call the API function
      const response = await DuplicateQuote(quoteData);
      toast.success('Quote duplicated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchQuotes());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);

export const updateQuoteStatus = createAsyncThunk<
  UpdateQuoteResponse, // Success response type
  UpdateQuoteStatusArgs, // Request payload type
  { rejectValue: UpdateQuotesError } // Error type for rejection
>(
  'quote/updateQuoteStatus',
  async (
    quoteData: UpdateQuoteStatusArgs,
    { dispatch, rejectWithValue },
  ) => {
    try {
      // Call the API function
      const response = await UpdateQuoteStatus(quoteData);
      toast.success('Quote status updated successfully!');

      // Optionally fetch updated quotes
      dispatch(fetchQuotes());

      return response.data; // Return response data
    } catch (error: any) {
      if (error.response) {
        const { message, messages } = error.response.data;
        return rejectWithValue({ message, messages });
      }

      return rejectWithValue({
        message: error.message || 'An unknown error occurred',
      });
    }
  },
);
